import React from "react"
import GlobalStateProvider from "./src/store/GlobalStateProvider"
import { MediaContextProvider } from "./src/utils/Media"
import SimpleReactLightbox from "simple-react-lightbox"

export const wrapRootElement = ({ element }) => (
  <SimpleReactLightbox>
    <MediaContextProvider>
      <GlobalStateProvider>{element}</GlobalStateProvider>
    </MediaContextProvider>
  </SimpleReactLightbox>
)

// // // ES5 way
// // // exports.onClientEntry = () => {
// // // ES6 way
// // export const onClientEntry = () => {
// //   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
// //   if (!(`IntersectionObserver` in window)) {
// //     import(`intersection-observer`)
// //     console.log(`# IntersectionObserver is polyfilled!`)
// //   }
// }
