import { useReducer } from "react"

const initState = {
  path: "",
  menuOpen: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PATH":
      return { ...initState, path: action.path }
    case "TOGGLE_MENU":
      return { ...initState, menuOpen: !state.menuOpen }
    default:
      return state
  }
}

const useGlobalState = () => {
  const [state, dispatch] = useReducer(reducer, initState)

  return { state, dispatch }
}

export default useGlobalState
