// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galeria-js": () => import("./../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-moje-rezervacie-js": () => import("./../src/pages/moje-rezervacie.js" /* webpackChunkName: "component---src-pages-moje-rezervacie-js" */),
  "component---src-pages-moje-udaje-js": () => import("./../src/pages/moje-udaje.js" /* webpackChunkName: "component---src-pages-moje-udaje-js" */),
  "component---src-pages-ochrana-osobnych-udajov-js": () => import("./../src/pages/ochrana-osobnych-udajov.js" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-js" */),
  "component---src-pages-prihlasenie-js": () => import("./../src/pages/prihlasenie.js" /* webpackChunkName: "component---src-pages-prihlasenie-js" */),
  "component---src-pages-registracia-js": () => import("./../src/pages/registracia.js" /* webpackChunkName: "component---src-pages-registracia-js" */),
  "component---src-pages-rezervacia-formular-js": () => import("./../src/pages/rezervacia-formular.js" /* webpackChunkName: "component---src-pages-rezervacia-formular-js" */),
  "component---src-pages-rezervacia-js": () => import("./../src/pages/rezervacia.js" /* webpackChunkName: "component---src-pages-rezervacia-js" */),
  "component---src-pages-rezervacia-potvrdenie-js": () => import("./../src/pages/rezervacia-potvrdenie.js" /* webpackChunkName: "component---src-pages-rezervacia-potvrdenie-js" */),
  "component---src-pages-vylety-js": () => import("./../src/pages/vylety.js" /* webpackChunkName: "component---src-pages-vylety-js" */),
  "component---src-templates-gallery-js": () => import("./../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */)
}

